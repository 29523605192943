<template>
  <div class="full" id="apply">
    <div class="green">
      <van-nav-bar title="提交订单" >
        <template #left>
          <van-icon :size="24" color="#ffffff" name="arrow-left" slot="left" @click="exit()" />
        </template>
      </van-nav-bar>
      <div class="green_bg"></div>
      <div class="p_r out">
        <!--time-->
        <div class="so_out so_out_top">
          <div class="title">{{buyTitle}}</div>

          <!--选择价格日历模块-->
          <div class="so_c_date d_f ali_c cur_p" @click="showBuyDate = true">
            <img style="margin-right: 5px" src="./img/s_c_date.png" alt="" class="socd_icon">
            <div class="flex_1">
              <span>游玩时间：</span>
              <span v-if="cDate || cTime"><span v-if="cDate">{{cDate}}</span><span style="margin-left: 10px" v-if="cTime">{{cTime}}</span></span>
              <span v-else class="grey">未选择</span>
            </div>
            <div class="t_r d_f ali_c">
              <span style="margin-right: 5px" class="grey">{{cDate || cTime ? '重选':'请选择'}}</span>
              <img src="./img/s_c_right.png" alt="">
            </div>
          </div>
        </div>
        <!--购买数量-->
        <div class="so_out so_out_num">
          <div class="d_f ali_c mb_12">
            <span class="title flex_1">{{typeName}}</span>
            <span class="price">￥{{price ? price/100 : 0}}</span>
          </div>
          <div class="d_f">
            <span class="flex_1 sub_title">{{remark}}</span>
            <div class="d_f ali_c so_buy_num">
              <img src="./img/so_d_dis.png" alt="" v-if="(isRealName && buyNum < checkArr.length + 1) || buyNum < 2">
              <img class="cur_p" src="./img/so_d.png" v-else alt="" @click="buyNum--">
              <span>{{buyNum}}</span>
              <img class="cur_p" src="./img/so_u.png" alt="" @click="buyNum++">
            </div>
          </div>
        </div>
        <!--出行人信息-->
        <div class="so_out so_person" v-show="isRealName">
          <div class="d_f ali_b">
            <div class="title">出行人信息</div>
            <div class="sub_title">需要添加{{buyNum}}位成人游客</div>
          </div>
          <div class="so_person_choose p_r d_f" v-for="(item,index) in checkArr" :key="index">
            <div class="sop_detail flex_1">
              <div class="name">{{item.linkManName}}</div>
              <div>身份证号码：{{item.cardNum}}</div>
            </div>
            <div class="d_f ali_c j_s">
              <img class="per_delete cur_p" src="./img/so_per_delete.png" alt="" @click="deletePer(index)">
              <img class="per_edit cur_p" src="./img/so_per_edit.png" alt="" @click="editPer(item,index)">
            </div>
            <div class="tag">游客{{index+1}}</div>
          </div>
          <div class="so_person_choose p_r d_f j_s" v-if="buyNum>checkArr.length">
            <div class="tag">游客{{checkArr.length+1}}</div>
            <div class="per_btn d_f ali_c has cur_p" @click="showPersonPop = true">
              <div class="flex_1 t_a">
                <img src="./img/per_per.png" alt="">
                <span>选择已有游客</span>
              </div>
            </div>
            <div class="per_btn d_f ali_c has_not cur_p" @click="addNewPer">
              <div class="flex_1 t_a">
                <img src="./img/per_add.png" alt="">
                <span>添加新游客</span>
              </div>
            </div>
          </div>
        </div>
        <!--联系人信息-->
        <div class="so_out so_contact">
          <div class="d_f ali_c">
            <span class="title">联系人信息</span>
            <span class="sub_title">接收短信通知</span>
          </div>
          <div class="d_f ali_b so_phone">
            <div class="phone_title">姓名</div>
            <input v-model="submitInfo.reservationPersonName" type="text" class="flex_1" placeholder="请输入" maxlength="11">
          </div>
          <div class="d_f ali_b so_phone">
            <div class="phone_title">联系电话</div>
            <input v-model="submitInfo.telephone" type="tel" class="flex_1" placeholder="请输入" maxlength="11">
          </div>
        </div>
        <!--使用消费劵-->
        <div class="so_out so_coupon so_integral" v-if="couponList">
          <div class="title d_f ali_b">
            <span style="margin-right: 10px">使用消费劵</span>
            <span class="jf_link">《<a @click="showJfPop = true">消费劵使用规则</a>》</span>
          </div>
          <div>
            <div class="sub p_r">
              <span>消费劵</span>
              <span>{{couponList.couponName}}</span>
              <span>￥{{couponList.couponAmount / 100}}</span>
              <img src="./img/coupon_right.png" alt="" class="p_a" @click="showCouponList = true">
            </div>
          </div>
        </div>
        <!--商品详情-->
        <div class="so_out so_contact so_depiction" v-if="depiction" style="margin-top: 10px;">
          <div class="d_f ali_c" style="margin-bottom: 20px">
            <span class="title">商品详情</span>
          </div>
          <div v-html="depiction"></div>
        </div>
      </div>
      <!--明细弹窗-->
      <div class="so_detail_out" v-if="showDetailPop">
        <div class="so_detail">
          <div class="head">订单明细</div>
          <div class="persons">
            <div class="d_f ali_c j_s content" v-for="(item,index) in checkArr" :key="index">
              <span>{{item.linkManName}}</span>
              <span>{{item.cardNum}}</span>
              <div class="coupon_tag">
                <div v-if="index === 0 && couponList">消费劵 抵扣
                  <span v-if="couponList.couponAmount < price ">{{couponList.couponAmount / 100}}</span>
                  <span v-else>{{price / 100}}</span>
                </div>
              </div>
              <span v-if="index === 0 && couponList">
                <span v-if="couponList.couponAmount < price ">￥{{price ? (price - couponList.couponAmount) / 100 :0}}</span>
                <span v-else>￥0</span>
              </span>
              <span v-else>￥{{price ? price / 100 : 0}}</span>
            </div>
          </div>

          <div class="d_f ali_c j_s content" v-if="isRealName">
            <span>{{name}} x{{checkArr.length}}</span>
            <span v-if="couponList">
              <span v-if="couponList.couponAmount < price * checkArr.length ">￥{{price ? (price * checkArr.length - couponList.couponAmount) / 100 :0}}</span>
              <span v-else>{{(price * (checkArr.length -1 )) / 100}}</span>
            </span>
            <span v-else>￥{{price ? price * checkArr.length / 100 :0}}</span>
          </div>
          <div class="d_f ali_c j_s content" v-else>
            <span>{{name}} x{{buyNum}}</span>
            <span v-if="couponList">
              <span v-if="couponList.couponAmount < price * buyNum ">￥{{price ? (price * buyNum - couponList.couponAmount) / 100 :0}}</span>
              <span v-else>{{(price * (buyNum -1 )) / 100}}</span>
            </span>
            <span v-else>￥{{price ? price * buyNum / 100 :0}}</span>
          </div>
        </div>
      </div>
      <!--底部按钮-->
      <div class="btn_out d_f ali_c" v-if="isKeyPop">
        <div class="flex_1 d_f integral_f">
          <div class="l d_f ali_b">
            <div class="title">总金额</div>
            <!--<div class="price">￥{{price ? totalPrice / 100 :0}}</div>-->
            <template v-if="isRealName">
              <template v-if="checkArr.length">
                <div class="price" v-if="couponList">
                  <span v-if="couponList.couponAmount < price * checkArr.length ">￥{{price ? (price * checkArr.length - couponList.couponAmount) / 100 :0}}</span>
                  <span v-else>{{(price * (checkArr.length -1 )) / 100}}</span>
                </div>
                <div class="price" v-else>￥{{price ? price * checkArr.length / 100 :0}}</div>
                <div style="text-decoration: line-through;margin-right: 20px">￥{{price ? price * checkArr.length / 100 :0}}</div>
              </template>
              <template v-else>
                <div class="price">￥0</div>
                <div style="text-decoration: line-through;margin-right: 20px">￥0</div>
              </template>
            </template>
            <template v-else>
              <div class="price" v-if="couponList">
                <span v-if="couponList.couponAmount < price * buyNum ">￥{{price ? (price * buyNum - couponList.couponAmount) / 100 :0}}</span>
                <span v-else>{{(price * (buyNum -1 )) / 100}}</span>
              </div>
              <div class="price" v-else>￥{{price ? price * buyNum / 100 :0}}</div>
              <div style="text-decoration: line-through;margin-right: 20px">￥{{price ? price * buyNum / 100 :0}}</div>
            </template>
            <div class="detail_pop d_f ali_c cur_p" v-if="!showDetailPop" @click="showDetailPop = true">
              <span>明细</span>
              <img src="./img/so_pop.png" alt="">
            </div>
            <div class="detail_pop d_f ali_c cur_p" v-if="showDetailPop" @click="showDetailPop = false">
              <span>收起</span>
              <img src="./img/so_down.png" alt="">
            </div>
          </div>
        </div>
        <div class="btn_pay cur_p" @click="pay">立即支付</div>
      </div>
      <!--选择出行人-->
      <div class="so_detail_out so_per" v-if="showPersonPop">
        <div class="so_detail">
          <div class="head d_f ali_c j_s">
            <div class="cur_p" @click="showPersonPop = false">取消</div>
            <div class="t_a">请选择游客</div>
            <div class="cur_p" @click="surePerson">确定</div>
          </div>
          <div class="contentPer">
            <van-checkbox-group v-model="result">
              <div class="cp_item d_f ali_c" v-for="(item,i) in perArr" :key="i">
                <van-checkbox icon-size="15px" shape="square" checked-color="#3FD467" :name="item.linkManId"></van-checkbox>
                <div class="name">{{item.linkManName}}</div>
                <div class="flex_1">{{item.cardNum}}</div>
                <div class="edit cur_p" @click="editPer(item)">
                  <img class="full_w" src="./img/so_per_edit.png" alt="">
                </div>
              </div>
            </van-checkbox-group>
          </div>
        </div>
      </div>
    </div>



    <!--点击预定出现日期弹窗-->
    <van-action-sheet v-model:show="showBuyDate">
      <div class="t_buy_date">
        <div class="head d_f ali_c">
          <div class="flex_1">{{buyTitle}}</div>
          <div class="close">
            <img class="full_w" src="./img/t_close.png" alt="" @click="closeBuyDate">
          </div>
        </div>
        <div class="red_tip">请在使用时间内前往景区</div>
        <template v-if="dateArr.length">
          <div class="tip">请选择使用日期</div>
          <div class="date_out d_f ali_c">
            <div class="over_out">
              <div class="flex_1 date_item_out">
                <template v-for="(item,index) in dateArr" :key="index">
                  <div class="date_item dis" v-if="item.dis">
                    <div class="d_f ali_c j_s">
                      <span>{{item.date}}</span>
                    </div>
                    <div class="price">不可订</div>
                    <div class="p_a tag">
                      <img class="full_w" src="./img/t_date_choose.png" alt="">
                    </div>
                  </div>
                  <!--v-if="item.dis === true ? false:true"-->
                  <div class="date_item" :id="'date'+index" v-else :class="chooseDate === index ? 'choose' : ''" @click="dateClick(index,item)">
                    <div class="d_f ali_c j_s">
                      <span>{{item.date}}</span>
                    </div>
                    <div class="price">￥{{item.sellPrice ? (item.sellPrice/100).toFixed(2) : 0}}</div>
                    <div class="p_a tag">
                      <img class="full_w" src="./img/t_date_choose.png" alt="">
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="date_more p_a d_f ali_c cur_p" @click="showDatePicker = true">
              <div class="flex_1">更多日期</div>
              <div class="date_more_icon">
                <img class="full_w" src="./img/td_right.png" alt="">
              </div>
            </div>

          </div>

        </template>
        <template v-if="timeArr.length">
          <div class="tip">请选择使用时段</div>
          <div class="time_out">
            <div class="over_out">
              <template v-for="(item,index) in timeArr" :key="index">
                <div v-if="!item.dis" :id="'date'+index" class="time_item" :class="chooseTime === index ? 'choose' : ''" @click="timeClick(index,item)">
                  <span style="margin-right: 5px">{{item.timeSlot}}</span>
                  <span>{{'￥'+(item.sellPrice ? (item.sellPrice/100).toFixed(2):0)}}</span>
                  <div class="p_a tag">
                    <img class="full_w" src="./img/t_date_choose.png" alt="">
                  </div>
                </div>
                <div v-else class="time_item dis">
                  <span>{{item.timeSlot}}</span>
                  <span>{{'￥'+(item.sellPrice ? (item.sellPrice/100).toFixed(2):0)}}</span>
                  <div class="p_a tag">
                    <img class="full_w" src="./img/t_date_choose.png" alt="">
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <div class="date_btn cur_p p_a" @click="sureDate">
          确定
        </div>
      </div>
    </van-action-sheet>

    <!--点击更多日期出现日历-->
    <!--:min-date="minDate"-->
    <!--:max-date="maxDate"-->
    <van-calendar title="选择使用日期" v-model:show="showDatePicker" color="#1ACC70" @confirm="confirmDate" @select="selectDate" :formatter="formatter" />

    <!--消费劵使用规则弹窗-->
    <jfRules v-if="showJfPop" @close="showJfPop = false"></jfRules>

    <!--优惠券列表弹窗-->
    <couponList @chooseCoupon="chooseCoupon" @close="showCouponList = false" v-if="showCouponList" :couponArr="couponArr"></couponList>
  </div>
</template>

<script>

  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || u.indexOf("iPhone") > -1 || u.indexOf('iPad') > -1 || u.indexOf("iOS") > -1; //ios终端

  Date.prototype.formatNoS=function (){
    var s='';
    s+=this.getFullYear()+'-';          // 获取年份。
    s+=(this.getMonth()+1)+"-";         // 获取月份。
    s+= this.getDate();                 // 获取日。
    return(s);                          // 返回日期。
  };

  import { NavBar, Icon, CheckboxGroup, Checkbox, Toast, ActionSheet, Calendar } from 'vant'
  import jfRules from "./jfRules";
  import couponList from "./../../../components/couponList";

  export default {
    name: "submitOrder",
    components:{
      'van-nav-bar': NavBar,
      'van-icon': Icon,
      'van-checkbox-group': CheckboxGroup,
      'van-checkbox': Checkbox,
      'van-calendar': Calendar,
      'van-action-sheet': ActionSheet,
      jfRules,
      couponList,
    },
    data() {
      return {
        apis:{
          perList:'/userLinkMan/slicedQueryLinkMan',
          pay:'/stlsOrder/create',
          findCommodity: '/commodity/findCommodity', // 查询商品详情
          tickets: '/commodity/queryCommodity',//商家商品列表
          queryThirdSystemCode: '/commodity/queryThirdSystemCode',//时间段
          findPriceCalendarByCommodityId: '/commodity/findPriceCalendarByCommodityId',//价格日历
          listCoupon: '/bizCoupon/getUserCoupon',//查询消费劵
          findUserInfo: '/userInfo/findUserInfo',//查询用户信息
          queryCouponListByCommditysId: '/bizCoupon/queryCouponListByCommditysId',//根据商品ID查询可用优惠券列表
        },
        showDetailPop:false,//明细弹窗
        showPersonPop:false,//选择出行人
        buyNum: 1,//购买数量
        result:[],//选择出行人checkbox数组,
        name:'',
        typeName:'',
        price:'',
        totalPrice: 0, // 总金额
        isSelfSupport: Number(this.$route.query.isSelfSupport), // 是否自营
        isRealName: Number(this.$route.query.isRealName), // 实名
        remark:'',
        commodityId:this.$route.query.commodityId,
        commodityIdFinal: '',//最终需要传给下单的商品id
        submitInfo:{
          date:'',
          linkmanName:'游客',
          orderType:'1',
          remark:'',
          source:3,
          telephone:'',
          reservationPersonName: '', // 联系人姓名
          couponCode: '', // 消费劵码
          reqOrderDetails:[
            /*{
              certificateNumber:'',
              certificateType:'',
              commodityId:this.$route.query.cTimeId,
              visitorName:'',
            }*/
          ],
          token:this.$global.token,
        },
        perArr:[],//联系人数组
        checkArr: [],
        isClick:true,
        showJfPop:false,
        isKeyPop:true,//键盘是否弹起
        //选日期的弹窗
        showBuyDate:false,
        showDatePicker:false,
        dateArr:[],//价格日历
        timeArr:[],//时间段
        chooseDate:null,//选择日期active
        chooseTime:null,//选择时间段active
        timeDate:null,//
        cDate:null,//选择的日期
        cTime:null,//选择的时间段
        cPrice:null,//第三方商品价格
        cTimeId:null,//第三方商品库ID
        buyTitle:null,//选中的商品
        commodityTypeId:null,
        couponList:null,//消费劵列表
        phoneNumberCoupon:null,//消费劵的手机号
        depiction:null,//富文本
        paying:false,//下单状态
        showCouponList:false,//是否展示优惠券列表
        couponArr:[],//是否展示优惠券列表
      }
    },
    mounted(){
      this.findCommodity(); // 查询商品详情
      this.findPriceCalendarByCommodityId() ;// 查询商品详情
    },
    activated(){
      this.initFun()
    },
    methods: {
      chooseCoupon(row){
        this.couponList = {...row};
        this.showCouponList = false;
      },
      queryCouponListByCommditysId(){
        let me = this;
        me.$post({
          url:me.apis.queryCouponListByCommditysId,
          params: {
            'token': me.$global.token,
            "bookUseDate": me.cDate,
            "commodityId": me.cTimeId,
          }
        }).then(res => {
          this.couponArr = res.data;
        });
      },
      initFun(){
        this.buyNum = sessionStorage.getItem('buyNum') ? sessionStorage.getItem('buyNum') : 1;
        this.submitInfo.telephone = sessionStorage.getItem('telephone') ? JSON.parse(sessionStorage.getItem('telephone')) : '';
        this.checkArr = sessionStorage.getItem('checkArr') ? JSON.parse(sessionStorage.getItem('checkArr')) : [];
        this.price = sessionStorage.getItem('price') || "";
        this.cDate = sessionStorage.getItem('cDate') || "";
        this.cTime = sessionStorage.getItem('cTime') || "";
        this.findCommodity(); // 查询商品详情
        this.findPriceCalendarByCommodityId(); // 查询价格日历
        this.findUserInfo(); // 查询用户信息
        if(Number(this.$route.query.isSelfSupport)){
          this.submitInfo.orderType = '4'
        }
      },
      findUserInfo(){
        let me = this;
        me.$post({
          url:me.apis.findUserInfo,
          params: {
            token: me.$global.token,
            deviceId: ''
          }
        }).then(res => {
          this.phoneNumberCoupon = res.data.phoneNumber
        });
      },
      //离开页面保存数据
      saveInfo(){
        sessionStorage.setItem('buyNum',this.buyNum);
        sessionStorage.setItem('telephone',this.submitInfo.telephone);
        sessionStorage.setItem('checkArr',JSON.stringify(this.checkArr));
        sessionStorage.setItem('result',JSON.stringify(this.result));
        this.price ? sessionStorage.setItem('price',this.price) : '';
        this.cDate ? sessionStorage.setItem('cDate',this.cDate) : '';
        this.cTime ? sessionStorage.setItem('cTime',this.cTime) : '';
      },
      // 查询消费劵
      listCoupon(){
        let me = this;
        me.$post({
          url:me.apis.listCoupon,
          params: {
            userPhone: me.phoneNumberCoupon,
            commodityId: me.cTimeId,
            disableDate: me.cDate,
          }
        }).then(res => {
          this.couponList = res.data;
          if(res.data !=null){
            this.submitInfo.couponCode = res.data.couponCode;
          }
        });
      },
      // 价格日历确定
      sureDate(){
        let _t = this;
        if(_t.dateArr.length && !_t.cDate){
          if(!_t.commodityIdFinal) return Toast('请选择日期');
        }
        if(_t.timeArr.length && !_t.cTime){
          if(!_t.commodityIdFinal) return Toast('请选择时间段');
        }
        this.showBuyDate = false;
      },
      // 价格日历
      findPriceCalendarByCommodityId(row,num){
        let me = this;
        me.$get({
          url:me.apis.findPriceCalendarByCommodityId,
          params: {
            commodityId: row ? row.commodityId : me.$route.query.commodityId,
            token: me.$global.token,
            pageSize: 999,
            startDate:me.timeDate
          }
        }).then(rsp => {
          if(num){
            me.cPrice = rsp.data[0].sellPrice;
            me.price = rsp.data[0].sellPrice;
          }else{
            if(rsp.data.length){
              let allDateArr = [];
              rsp.data.map((item,i) => {
                let dis = false;
                let tStr = '';
                if(isiOS){
                  // var t = new Date(time.replace(/-/g,'/')).getTime();
                  tStr = new Date((item.startDate+' '+'23:59:59').replace(/-/g,'/')).getTime()
                }else{
                  tStr = new Date(item.startDate+' '+'23:59:59').getTime()
                }
                if(tStr<new Date().getTime()){
                  dis = true;
                }
                let obj = {
                  date:item.startDate,
                  dis:dis,
                  commodityId:item.commodityId,
                  balancePrice:item.balancePrice,
                  commodityName:item.commodityName,
                  // endDate:item.endDate,
                  priceCalendarId:item.priceCalendarId,
                  priorityLevel:item.priorityLevel,
                  sellPrice:item.sellPrice,
                  sellerType:item.sellerType,
                  shopPrice:item.shopPrice,
                  startDate:item.startDate,
                  typeId:item.typeId,
                };
                if(item.startDate === me.cDate){
                  me.price = item.sellPrice
                }
                allDateArr.push(obj)
              });
              me.dateArr = JSON.parse(JSON.stringify(allDateArr));
              // me.showBuyDate = true;
            }else{
              me.commodityIdFinal = row.commodityId;
              Toast('该商品暂无库存！')
            }
          }
        });
      },
      // 关闭价格日历
      closeBuyDate(){
        this.showBuyDate = false;
        this.timeDate = null;
      },
      //日期弹窗确认
      confirmDate(date){
        let _t = this;
        _t.dateArr.map((item,index) => {
          let itemDate = '';
          if(isiOS){
            itemDate = new Date(item.date.replace(/-/g,'/')+' 00:00:00').getTime();
          }else{
            itemDate = new Date(item.date+' 00:00:00').getTime();
          }
          if(new Date(date).getTime() === itemDate){
            // _t.chooseDate = index
            _t.dateClick(index,item)
          }
        });
        this.showDatePicker = false
      },
      //时间段
      queryThirdSystemCode(date){
        let me = this;
        me.chooseTime = null;
        me.cTime = null;
        me.commodityIdFinal = null;
        me.$get({
          url:me.apis.tickets,
          params: {
            parentId: me.cTimeId,
            commodityTypeId: me.commodityTypeId,
            token: me.$global.token,
            searchTime:date.split(' ')[0],
          }
        }).then(rsp => {//thirdName
          me.timeArr = rsp.data;
          if(!rsp.data.length){
            me.commodityIdFinal = JSON.parse(JSON.stringify(me.cTimeId));
          }else{
            me.timeArr.map((item,index) => {
              item.dis = null;
              let itemDate = null;
              if(isiOS){
                itemDate = new Date(date.replace(/-/g,'/')+ ' '+item.timeSlot.split('-')[1]).getTime();
              }else{
                itemDate = new Date(date+ ' '+item.timeSlot.split('-')[1]).getTime();
              }
              if(itemDate<new Date().getTime()){
                item.dis = true
              }
            })
          }
        });
      },
      //点击时间段
      timeClick(index,row){
        let _t = this;
        _t.chooseTime = index;
        _t.cTime = row.timeSlot;
        _t.commodityIdFinal = row.commodityId;
        _t.cPrice = row.sellPrice;
        _t.cTimeId = row.commodityId;
        this.listCoupon();
        this.queryCouponListByCommditysId();
        _t.findPriceCalendarByCommodityId(row,1);

      },
      //点击日期
      dateClick(index,row){
        this.chooseDate = index;
        document.getElementById('date'+index).scrollIntoView();
        let dateStr = isiOS ? row.date.replace(/-/g,'/')+' 00:00:00' : row.date+' 00:00:00';
        this.cPrice = row.sellPrice;
        this.price = row.sellPrice;
        this.cTimeId = row.commodityId;
        this.selectDate(dateStr);
      },
      selectDate(date){
        let _t = this;
        let d = new Date(date);
        let m = (d.getMonth()+1)<10 ? '0'+(d.getMonth()+1) : (d.getMonth()+1);
        let day = (d.getDate())<10 ? '0'+(d.getDate()) : (d.getDate());
        _t.cDate = d.getFullYear()+'-'+m+'-'+day;
        _t.queryThirdSystemCode(new Date(date).formatNoS());
        this.timeDate = new Date(date).formatNoS();
        this.listCoupon();
        this.queryCouponListByCommditysId();
      },
      //渲染价格自定义文案
      formatter(day) {
        let _t = this;
        day.type = 'disabled';
        _t.dateArr.map((item,index) => {
          let itemDate = '',
            dayDate = new Date(day.date).getTime(),
            nowDate = new Date().getTime(),
            cDate = '';
          if(isiOS){
            itemDate = new Date(item.date.replace(/-/g,'/')+' 00:00:00').getTime();
            cDate = _t.cDate ? new Date(_t.cDate.replace(/-/g,'/')+' 00:00:00').getTime() : '';
          }else{
            itemDate = new Date(item.date+' 00:00:00').getTime();
            cDate = _t.cDate ? new Date(_t.cDate+' 00:00:00').getTime() : '';
          }
          if(dayDate === itemDate){
            if(!day.bottomInfo){
              day.bottomInfo = item.sellPrice ? '￥'+item.sellPrice/100 :'￥0';
            }
            if(dayDate>nowDate){
              day.type = '';
            }
            if(cDate === itemDate){
              day.type = 'selected';
            }
          }
        });
        return day;
      },
      //立即支付
      pay(){
        let _t = this;
        let regPhone = /^1[3456789]\d{9}$/;//电话正则
        if(_t.isRealName){
          if(!_t.checkArr.length) return Toast('请选择出行人');
          if(_t.checkArr.length != _t.buyNum) return Toast('还需选择'+(_t.buyNum-_t.checkArr.length)+'个出行人');
        }
        if(!_t.cDate)  return Toast('请选择游玩时间');
        _t.submitInfo.date = _t.cDate;
        if(!_t.submitInfo.reservationPersonName) return Toast('请输入联系人姓名');
        if(!_t.submitInfo.telephone) return Toast('请输入联系电话');
        if(!regPhone.test(_t.submitInfo.telephone)) return Toast('电话输入有误');
        _t.submitInfo['totalDiscountPoints'] = 0;
        _t.submitInfo['isRealName'] = _t.isRealName;
        _t.submitInfo.reqOrderDetails = [];
        if(_t.cTimeId ==null) return Toast('请选择游玩时间');
        _t.checkArr.map((item,index) => {
          let obj = {
            certificateNumber:item.cardNum,
            certificateType:0,
            linkManId:item.linkManId,
            commodityId:_t.cTimeId,
            visitorName:item.linkManName,
            discountPoints: 0,
          };
          _t.submitInfo.reqOrderDetails.push(obj);
        });
        if(!_t.isRealName){
          for(let i = 0; i < _t.buyNum; i++){
            let obj = {
              certificateNumber:'',
              certificateType:0,
              linkManId:'',
              commodityId:_t.cTimeId,
              visitorName:'',
              discountPoints: 0,
            };
            _t.submitInfo.reqOrderDetails.push(obj);
          }
        }
        if(_t.paying) return Toast('努力下单中，请稍等');
        _t.paying = true;
        _t.$post({
          url:_t.apis.pay,
          params: _t.submitInfo
        }).then(res => {
          _t.paying = false;
          _t.$global.isAddPerson = false;
          _t.saveInfo();
          let couponPrice = 0;
          if(_t.couponList){
            if(_t.couponList.couponAmount < _t.price){
              couponPrice = _t.couponList.couponAmount
            }else{
              couponPrice = _t.price
            }
          }
          _t.$router.push({
            path:'/payWay',
            query:{
              code:res.data,
              price:_t.price,
              couponAmount:couponPrice,
              buyNum:_t.buyNum
            }
          })
        }).catch(err => {
          _t.paying = false;
          Toast(err.message);
        });
      },
      //删除已选联系人
      deletePer(index){
        let _t = this;
        _t.checkArr.splice(index,1);
        _t.result.splice(index,1);
        sessionStorage.setItem('checkArr',JSON.stringify(_t.checkArr));
        sessionStorage.setItem('result',JSON.stringify(_t.result));
      },
      //编辑新乘客
      editPer(item){
        sessionStorage.setItem('linkManId',JSON.stringify(item.linkManId));
        this.saveInfo();
        this.gotoPer('edit',item.linkManId,item.userId)
      },
      //添加新乘客
      addNewPer(){
        this.saveInfo();
        this.gotoPer('add')
      },
      gotoPer(type,key,userId){
        this.$router.push({
          path:'/person',
          query:{
            key:key,
            userId:userId,
            type:type
          }
        })
      },
      //选择出行人确定
      surePerson(){
        let _t = this;
        if(_t.result.length>_t.buyNum) return Toast('您只需选择'+(_t.buyNum)+'个出行人');
        _t.perArr.map((item,index) => {
          _t.result.map((it,idx) => {
            if(item.linkManId === it){
              _t.checkArr.push(item);
            }
          })
        });
        let hash = {};
        _t.checkArr = _t.checkArr.reduce((preVal, curVal) => {
          hash[curVal.linkManId] ? '' : hash[curVal.linkManId] = true && preVal.push(curVal);
          return preVal
        }, []);
        _t.checkArr = JSON.parse(JSON.stringify(_t.checkArr));
        sessionStorage.setItem('checkArr',JSON.stringify(_t.checkArr));
        sessionStorage.setItem('result',JSON.stringify(_t.result));
        _t.showPersonPop = false;
      },
      //获取联系人列表
      getPerList(){
        let me = this;
        me.$post({
          url:me.apis.perList,
          params: {
            pageIndex: 1,
            pageSize: 100,
            token: me.$global.token
          }
        }).then(res => {
          this.perArr = res.data.data;
          let linkManId = sessionStorage.getItem('linkManId');
          let checkIndex = null;
          this.checkArr.map((item,index) => {
            this.result.push(item.linkManId);
          });
          if(linkManId){
            this.checkArr.map((item,index) => {
              if(item.linkManId == linkManId){
                checkIndex = index;
              }
            });
            this.perArr.map((item,index) => {
              if(item.linkManId == linkManId){
                this.checkArr[checkIndex] = item;
              }
            })
          }
          if(this.$global.isAddPerson){
            this.checkArr.push(this.perArr[0]);
            this.surePerson();
          }
        });
      },
      // 查询商品详情
      findCommodity(){
        let me = this;
        me.$get({
          url: me.apis.findCommodity,
          params: {
            token: me.$global.token,
            commodityId: me.$route.query.commodityId
          }
        }).then(res => {
          me.buyTitle = res.name;
          me.remark = res.remark;
          me.commodityTypeId = res.commodityTypeId;
          me.typeName = res.typeName;
          me.price = res.sellPrice;
          me.depiction = res.depiction;
          // console.log(res)
        });
      },
      exit(){
        this.$global.isAddPerson = false;
        let isCloseCoupon = sessionStorage.getItem('hasCloseCoupon') ? true : false;
        sessionStorage.clear();
        sessionStorage.setItem('LOGIN_STATUS',2);
        sessionStorage.setItem('token',this.$global.token);
        isCloseCoupon ? sessionStorage.setItem('hasCloseCoupon', 1) : '';
        this.$router.go(-1)
      },
    },
    created() {
      let _t = this;
      _t.initFun();
      _t.getPerList();
      var clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      window.onresize = function() {
        var nowClientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (clientHeight - nowClientHeight > 300 ) {//因为ios有自带的底部高度
          _t.isKeyPop = false;
          //键盘弹出的事件处理
          document.getElementById("apply").classList.add("focusState");
        }
        else {
          _t.isKeyPop = true;
          //键盘收起的事件处理
          document.getElementById("apply").classList.remove("focusState");
        }
      };
    },
  }
</script>

<style scoped lang="scss" src="./tiketDetails.scss"></style>
<style scoped lang="scss">
  .coupon_tag{
    min-width: 84px;
    div{
      padding: 0 3px;
      height: 18px;
      border: 1px solid #FF3E6E;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FF3E6E;
    }
  }
  .so_c_date{
    .grey{
      color: #D4D4D4;
    }
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #141212;
    img{
      width: 7px;
      height: 12px;
    }
    .socd_icon{
      width: 18px;
      height: 18px;
    }
    border-bottom: 1px solid #000000;
    padding: 12px 0;
  }
  .jf_link{
    font-size: 12px;
    color: #b5b5b5;
    a{
      color: #b5b5b5;
      text-decoration: underline;
    }
  }
  .focusState {position: absolute;}
  .so_person{
    .so_person_choose{
      .per_btn{
        width:140px;
        height:30px;
        background:rgba(255,255,255,1);
        border:1px solid rgba(63,212,103,1);
        border-radius:2px;
        font-size: 12px;
        font-weight: 500;
        color:rgba(63,212,103,1);
        img{
          width: 19px;
          margin-right: 7px;
          vertical-align: middle;
        }
        &.has_not{
          background:rgba(63,212,103,1);
          color: white ;
          img{
            width: 18px;
            margin-right: 7px;
          }
        }
      }
      .tag{
        width:39px;
        height:16px;
        background:rgba(63,212,103,1);
        border-radius:2px;
        text-align: center;
        line-height: 16px;
        font-size:10px;
        font-weight:400;
        color:rgba(255,255,255,1);
        left: 0;
        top: 0;
        position: absolute;
      }
      .per_delete{
        width: 15px;
        margin-right: 32px;
      }
      .per_edit{
        width: 19px;
      }
      .name{
        margin-bottom:9px;
      }
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
      width:313px;
      background:rgba(255,255,255,1);
      border:1px solid rgba(207,207,207,1);
      border-radius:2px;
      margin: 12px auto 0 auto;
      padding: 25px 10px 13px 10px;
    }
    .sub_title{
      font-size:12px;
      font-weight:400;
      color:rgba(20,18,18,1);
      line-height:35px;
    }
    .title{
      font-size:16px;
      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-right: 14px;
    }
    padding: 11px 11px 13px 11px;
  }
  .so_contact{
    // &.so_out{
    //   margin-bottom: 100px;
    // }
    .so_phone{
      input{
        border: none;
        outline: none;
        font-size: 12px;
        font-weight:400;
        color:rgba(174,174,174,1);
      }
      .phone_title{
        font-size: 14px;
        font-weight: 500;
        color:rgba(20,18,18,1);
        width: 95px;
      }
      margin-top: 17px;
    }
    .sub_title{
      font-size:12px;
      font-weight:400;
      color:rgba(20,18,18,1);
    }
    .title{
      width: 95px;
      font-size:16px;
      font-weight:bold;
      color:rgba(20,18,18,1);
    }
    padding: 11px 11px 13px 11px;
  }
  .so_out_num{
    .so_buy_num{
      img{
        width: 18px;
      }
      span{
        font-size:14px;
        font-weight:bold;
        color:rgba(20,18,18,1);
        margin: 0 9px;
      }
    }
    .sub_title{
      font-size:12px;
      font-weight:400;
      color:rgba(20,18,18,1);
    }
    .title{
      font-size:14px;
      font-weight:500;
      color:rgba(20,18,18,1);
    }
    .price{
      font-size:14px;
      font-weight:bold;
      color:rgba(255,62,110,1);
    }
    padding: 13px 13px 9px 9px;
  }
  .so_out_top{
    .sub{
      font-size:12px;
      font-weight:400;
      color:rgba(20,18,18,1);
      span{
        margin-right: 22px;
      }
    }
    padding: 9px 9px 17px 9px;
    .title{
      font-size:18px;
      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-bottom: 13px;
    }
  }
  .so_out{
    background:rgba(255,255,255,1);
    border-radius:5px;
    margin-bottom: 10px;
  }
  .out{
    top: -20px;
    padding: 0 10px;
  }
  .so_detail_out{
    &.so_per{
      .contentPer{
        .cp_item{
          .edit{
            width: 18px;
          }
          .name{
            width: 68px;
            margin-left: 15px;
          }
          font-size:12px;
          font-weight:400;
          color:rgba(20,18,18,1);
          height: 39px;
          padding: 0 27px 0 24px;
          border-bottom:1px solid rgba(179,179,179,1);
        }
        padding-bottom: 26px;
        max-height: 250px;
        overflow: auto;
      }
      .head{
        .cur_p{
          font-size:14px;
          font-weight:400;
          color:rgba(87,87,87,1);
        }
        padding-right: 21px;
        font-size:18px;

        font-weight:bold;
        color:rgba(51,51,51,1);
      }
      .so_detail{
        bottom: 0;
      }
      height: 100%;
    }
    .so_detail{
      .content{
        height: 42px;
        font-size:14px;
        font-weight:500;
        color:rgba(20,18,18,1);
        padding: 0 24px;
        .reduce{
          border: 1px solid #ff3e6e;
          color: #ff3e6e;
          border-radius: 15px;
          padding: 2px 5px;
          font-size: 10px;
        }
      }
      .head{
        height:44px;
        background:rgba(247,247,247,1);
        line-height: 44px;
        font-size:18px;
        font-weight:bold;
        color:rgba(51,51,51,1);
        padding-left: 21px;
      }
      position: absolute;
      width: 100%;
      bottom: 3px;
      left: 0;
      background:rgba(255,255,255,1);
      border-radius:7px 7px 0px 0px;
      overflow: hidden;
    }
    .persons{
      .content{
        font-size: 12px;
        border-bottom: 1px solid #e1e1e1;
      }
    }
    width: 100%;
    height: calc(100% - 45px);
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background:rgba(0,0,0,0.5);
    z-index: 1000;
  }
  .so_depiction{
    padding: 15px;
    ::v-deep img{
      max-width: 100%;
    }
  }
  .so_integral{
    padding: 9px 9px 17px 9px;
    margin-bottom: 0;
    .sub{
      font-size:14px;
      font-weight:400;
      color:rgba(20,18,18,1);
      display: flex;
      flex-wrap:wrap;
      img.p_a{
        width: 7px;
        height: 12px;
        right: 10px;
        top: 2px;
        cursor: pointer;
      }
      span{
        margin-right: 25px;
        &:last-child{
          color: #FF3E6E;
        }
      }
    }
    .title{
      font-size:18px;
      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-bottom: 13px;
    }
    .check{
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: 1px solid #c0c0c0;
      border-radius: 50%;
      cursor: pointer;
    }
    .checked{
      border: none;
      background: url(../../../assets/img/icon-useintegral.png) no-repeat center center/auto 100%;
    }
  }
  .btn_out{
    .integral_f{
      justify-content: space-between;
      align-items: center;
    }
    .integral{
      margin-right: 5px;
      text-align: right;
      li{
        font-size: 12px;
        list-style: none;
      }
      p{
        font-size: 12px;
        color: #FF3E6E;
      }
    }
    .btn_pay{
      width:109px;
      height:30px;
      line-height:30px;
      background:linear-gradient(90deg,rgba(146,228,96,1),rgba(59,211,103,1));
      border-radius:15px;
      font-size:14px;
      box-sizing: border-box;
      font-weight:bold;
      color:rgba(255,255,255,1);
      text-align: center;
    }
    .detail_pop{
      font-size:10px;
      font-weight:400;
      color:rgba(87,87,87,1);
      img{
        width: 10px;
        margin-left: 4px;
      }
    }
    .price{
      font-size:14px;
      font-weight:bold;
      color:rgba(255,62,110,1);
      margin-right: 13px;
    }
    .title{
      font-size:16px;
      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-right: 6px;
    }
    position: fixed;
    height: 45px;
    bottom: 0;
    width: 100%;
    left: 0;
    background:rgba(255,255,255,1);
    box-shadow:0px -1px 2px 0px rgba(6,0,1,0.05);
    padding: 0 21px;
    box-sizing: border-box;
  }
  .full{
    overflow: auto;
    background-color: #EEEEEE;
    .green{
      padding-bottom: 35px;
    }
  }
  .green_bg{
    height: 20px;
    background-color: #3FD467;
  }
  .van-nav-bar{
    background-color: #3FD467;
    &::after{
      border: none;
    }
  }
</style>
