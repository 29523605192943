<template>
  <div class="container p_f jr_out">
    <van-nav-bar title="消费劵使用规则" >
      <template #left>
        <van-icon :size="24" color="#000000" name="arrow-left" slot="left" @click="$emit('close')" />
      </template>
    </van-nav-bar>
    <div style="padding: 10px" class="jr_content">
      <div v-html="jfRules"></div>
    </div>

  </div>
</template>

<script>
  import { NavBar, Icon} from 'vant'
  export default {
    name: "jfRules",
    components:{
      'van-nav-bar': NavBar,
      'van-icon': Icon,
    },
    data() {
      return {
        apis:{
          findByCode: '/content/findByColumnCode' // 查询积分比例
        },
        jfRules:'',
      }
    },
    methods: {
      // 查询积分规则
      findByCode(){
        let me = this;
        me.$get({
          url:me.apis.findByCode,
          params: {
            columnCode:'bxjyhgz',
          }
        }).then(res => {
          me.jfRules = res.content

        });
      },
    },
    created(){
      this.findByCode()
    }
  }
</script>

<style scoped lang="scss">
  .jr_out{
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: white;
    .jr_content{
      height: calc(100% - 60PX);
      box-sizing: border-box;
      overflow-y: auto;
    }
  }

</style>
